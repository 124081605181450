import { ApproverReview } from '@hobt/arrears-domain';
import {
  CancellationReason,
  ClaimDocumentType,
  ClaimTypeCode,
  DeclineReason,
  InternalClaimStatus
} from '@hobt/claim-domain';
import { ApprovalDecisionCode, NotificationScenario, TitleTypeCode } from '@hobt/constants';

import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';

export interface NotificationProps {
  buttonText: FieldValue;
  fields: NotificationFields;
}

export interface NotificationFields {
  all: FieldValue;
  endOfPage: FieldValue;
  read: FieldValue;
  title: FieldValue;
  unread: FieldValue;
  markAll: FieldValue;
  noUnreadNotificationsMessages: FieldValue;
  noReadNotificationsMessages: FieldValue;
  noNotificationsMessages: FieldValue;
  lenderNumber: FieldValue;
  currentDate: FieldValue;
  closeNotification: FieldValue;
  unreadNotificationText: FieldValue;
  bodyMessage1: FieldValue;
  bodyMessage2: FieldValue;
  arrearsApprovalType?: FieldValue;
  arrearsPrimaryApproverRole?: FieldValue;
  claimApprovalType?: FieldValue;
  notificationScenario: NotificationScenarioProps;
  claimStatusList?: SitecoreDropdownList;
  documentRequestCodeCategoryList?: SitecoreDropdownList;
  typeOfClaimList?: SitecoreDropdownList;
  cancellationList?: SitecoreDropdownList;
  declineReasonList?: SitecoreDropdownList;
  approvalDecisionCodesList?: SitecoreDropdownList;
  userLevelList?: SitecoreDropdownList;
}

export interface NotificationScenarioProps {
  fields: NotificationScenarioPropsFields;
}
export interface NotificationScenarioPropsFields {
  listItems: NotificationScenarioPropsItems[];
}

export interface NotificationScenarioPropsItems {
  fields: NotificationScenarioPropsItemsFields;
}
export interface NotificationScenarioPropsItemsFields {
  serviceCode: FieldValue;
  contentTemplate: FieldValue;
  contentTitle?: FieldValue;
  emailSubjectLine?: FieldValue;
}

export enum NotificationStatus {
  ALL = 0,
  READ = 1,
  UNREAD = 2
}

export interface Notification {
  expiryTimestamp?: string;
  elementData?: NotificationElementDataFields;
  homeownerID?: string;
  issuedTimestamp?: string;
  issuerUserID: string;
  notificationID: string;
  scenarioID: NotificationScenario;
  statusCode: NotificationStatus;
}

export interface Payee {
  approvedLenderCode?: string;
  financialInstitutionCode?: string;
  locationId?: string;
  partyId?: string;
  transitNumber?: string;
}

export interface NotificationElementDataFields {
  approvalDecisionCode?: ApprovalDecisionCode;
  approvalType?: string;
  approvedLenderReferenceNumber?: string;
  approverName?: string;
  approverReviews?: ApproverReview[];
  baseApprovalTitleTypeCode?: TitleTypeCode;
  cancellationReasonCode?: CancellationReason;
  claimStatus?: InternalClaimStatus;
  claimTypeCode?: ClaimTypeCode;
  cmhcLoanAccountNumber?: string;
  declineReasonCode?: DeclineReason;
  decisionTimestamp?: string;
  documentRequestCategoryCode?: ClaimDocumentType[];
  link?: string;
  payeeList?: Payee[];
  remarkText?: string;
  sequenceNumber?: number;
}

// Reducer
export type NotificationsState = {
  statusFilter: NotificationStatus;
  showNotificationSidebar: boolean;
  showNotificationContents: boolean;
  unreadNotifications: number;
  notifications: Notification[];
  selectedNotification?: Notification;
  notificationContent?: NotificationContent;
  buttonLoading?: boolean;
  dataLoading?: boolean;
};

export enum NotificationActionType {
  SHOW_NOTIFICATION_SIDEBAR,
  HIDE_NOTIFICATION_CONTENT,
  HIDE_NOTIFICATION_SIDEBAR,
  UPDATE_FILTER,
  UPDATE_NOTIFICATION_CONTENT,
  UPDATE_NOTIFICATIONS,
  SET_BUTTON_LOADING,
  SET_DATA_LOADING
}

export type NotificationAction = {
  action: NotificationActionType;
  payload?: NotificationStatus | unknown | Notification[];
};

export type NotificationScenarioLookup = {
  [key: string]: {
    title: FieldValue;
    content: FieldValue;
  };
};

export type NotificationListItem = {
  id: string;
  title: string;
  date: string;
  status: NotificationStatus;
  lender?: string;
  scenarioId: string;
  tokenData?: { [key: string]: string } | NotificationElementDataFields;
};

export type NotificationContent = {
  title: string;
  date: string;
  lender?: string;
  content: string;
};

export type TokenReplacement = {
  [key: string]: string | undefined;
};

export type UpdateNotificationContentPayload = {
  selectedNotification?: Notification;
  notificationContent?: NotificationContent;
};

export const CRM_PAYEE_NOTIFICATION_SCENARIOS = [
  NotificationScenario.DuplicateLenders,
  NotificationScenario.LenderWithoutVendorFlag,
  NotificationScenario.InvalidFinancialData,
  NotificationScenario.UnprocessedPayees
];

export const APPROVAL_NOTIFICATION_SCENARIOS = [
  NotificationScenario.ApprovalRequired,
  NotificationScenario.PrimaryApproved,
  NotificationScenario.PrimaryNeedsReview,
  NotificationScenario.SecondaryNeedsReview
];

export const ARREARS_SUBMISSION_SCENARIOS = [
  // Unsed
  // NotificationScenario.ArrearsSubmissionFailedDataFormat,
  // NotificationScenario.ArrearsSubmissionFailedDuplicateRecords,
  // NotificationScenario.ArrearsSubmissionFailedInvalidDataSelected,
  // NotificationScenario.ArrearsSubmissionFailedInvalidMonth,
  // NotificationScenario.ArrearsSubmissionSuccessful,
  NotificationScenario.ArrearsFileValidationDelayDetection,
  NotificationScenario.ArrearsRecordsNotHydrated
];

export const CONTACT_UPDATE_SCENARIOS = [
  // NotificationScenario.ContactUpdateFailed,
  // NotificationScenario.ContactUpdatePending,
  // NotificationScenario.ContactUpdateSuccessful,
  // NotificationScenario.ContactCreationUpdateError
];

export const CLAIM_SUBMISSION_SCENARIOS = [NotificationScenario.ClaimSubmissionFailed];
